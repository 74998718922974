* {
    border: 0;
    cursor: none;
    display: inline-block;
    font-size: 0;
    font-smooth: antialiased;
    font-variant-ligatures: none;
    line-height: 0%;
    margin: 0;
	padding: 0;
	vertical-align: baseline;
	text-rendering: optimizeLegibility;
	text-shadow: 0px 0px 1px rgba(44, 45, 46, .01) ;
}

:root {
    // Theme Colors
    --primary: rgb(163, 173, 255);
    --body: rgb(255, 255, 255);
    // Colors
    --text: rgb(44, 45, 46);
    --text-25: rgba(44, 45, 46,.25);
    --foreground: rgb(255, 255, 255);
    --foreground-25: rgba(255, 255, 255, .25);
    // --foreground: rgb(237, 237, 237);
    // --foreground-25: rgba(237, 237, 237, .25);
    // Transitions
    --t-fast: all 0.25s ease;
    --t-regular: all 0.5s ease;
    --t-slow: all 1s ease;
    // Levels
    --l-small: 0px 0px 4px var(--text-25);
    --l-regular: 0px 0px 4px var(--text-50);
    @media (prefers-color-scheme: dark) {
        // Theme Colors
        --primary: rgb(128, 128, 255);
        --body: rgb(0, 0, 0);
        // Colors
        // --text: rgb(237, 237, 237);
        // --text-25: rgba(237, 237, 237, .25);
        --text: rgb(255, 255, 255);
        --text-25: rgba(255, 255, 255, .25);
        --foreground: rgb(44, 45, 46);
        --foreground-25: rgba(44, 45, 46,.25);
    }
}


::selection {
    color: var(--text);
    background: var(--primary);
  }

// Fonts
@font-face {
    font-family: "primary";
    src: local("LaNord"),
        url("./fonts/LaNord-Light.woff") format("truetype");
    font-weight: regular;
    font-display: swap;
}

@font-face {
    font-family: "secondary";
    src: local("LaNord"),
        url("./fonts/LaNord-Regular.woff") format("truetype");
    font-weight: regular;
    font-display: swap;
}

h1 {
    display: none;
    font-size: 17vw;
    font-weight: 400;
    line-height: 100%;
    @media screen and (max-width: 768px) {font-size: 15vw;}
}

h2 {
    display: block;
    font-family: "secondary", sans-serif;
    font-size: 2.9vw;
    font-weight: 400;
    line-height: 140%;
    @media screen and (max-width: 768px) {font-size: 6vw;}
}

h3 {
    font-family: "secondary", sans-serif;
    font-size: 3vw;
    font-weight: 400;
    line-height: 140%;
    @media screen and (max-width: 768px) {font-size: 6vw;}
}

h4 {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 180%;
    text-transform: uppercase;
}

p {
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    line-height: 180%;
    @media screen and (min-width: 1400px) {font-size: 1.2vw;}

}

a {
    color: var(--text);
    font-size: 15px;
    font-weight: 400;
    line-height: 160%;
    position: relative;
    transition: var(--t-regular);
    text-decoration: none;
    &::after {
        background: var(--text);
        bottom: 4px;
        content: "";
        height: 0.5px;
        left: 0;
        position: absolute;
        width: 100%;
    }
    &:hover {
        color: var(--primary);
        &::after {background: var(--primary);}
    }
}

span {
    color: var(--primary);
}

img {
    border-radius: 5px;
    max-width: 100%;
}

//Class
html {
    height: 100%;
    width: 100%;
    margin: 0;
}

body {
    background: var(--body);
    color: var(--text);
    font-family: "primary", sans-serif;   
    height: 100%; 
    margin: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
}

#root {
    height: 100%;
    position: relative;
    width: 100%;
}

.wrapper {
    display: block;
    height: calc(100vh - 100px) !important;
    overflow-x: hidden;
    padding: 50px;
    position: relative;
    overflow-y: scroll;
    @media screen and (max-width: 768px) {
        height: calc(100vh - 50px) !important;
        padding: 25px;
    }
    .grid_container {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(12,[col] 1fr);
        @media screen and (max-width: 768px) {grid-gap: 10px;}
        .col{
            &[data-column="1"]{grid-column: span 1;}
            &[data-column="2"]{grid-column: span 2;}
            &[data-column="3"]{grid-column: span 3;}
            &[data-column="4"]{grid-column: span 4;}
            &[data-column="5"]{grid-column: span 5;}
            &[data-column="6"]{grid-column: span 6;}
            &[data-column="7"]{grid-column: span 7;}
            &[data-column="8"]{grid-column: span 8;}
            &[data-column="9"]{grid-column: span 9;}
            &[data-column="10"]{grid-column: span 10;}
            &[data-column="11"]{grid-column: span 11;}
            &[data-column="12"]{grid-column: span 12;}
            @media screen and (max-width: 768px) {
                &[data-column-mq="1"]{grid-column: span 1;}
                &[data-column-mq="2"]{grid-column: span 2;}
                &[data-column-mq="3"]{grid-column: span 3;}
                &[data-column-mq="4"]{grid-column: span 4;}
                &[data-column-mq="5"]{grid-column: span 5;}
                &[data-column-mq="6"]{grid-column: span 6;}
                &[data-column-mq="7"]{grid-column: span 7;}
                &[data-column-mq="8"]{grid-column: span 8;}
                &[data-column-mq="9"]{grid-column: span 9;}
                &[data-column-mq="10"]{grid-column: span 10;}
                &[data-column-mq="11"]{grid-column: span 11;}
                &[data-column-mq="12"]{grid-column: span 12;}
            }
        }
    }
}

.space {
    display: block;
    &[data-space="xsmall"]{height: 15px;}
    &[data-space="small"]{height: 25px;}
    &[data-space="medium"]{height: 50px;}
    &[data-space="large"]{height: 100px;}
    &[data-space="xlarge"]{height: 200px;}
}

.highlight {color: var(--primary);}

.divider {
    background: var(--text-25);
    height: 1px;
    width: 100%;
}

// Device
.desktop {
    display: inline-block;
    @media screen and (max-width: 768px) {display: none;}
}
.mobile {
    display: none;
    @media screen and (max-width: 768px) {display: inline-block;}
}