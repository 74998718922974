.navbar {
    backdrop-filter: blur(2px);
    background: var(--foreground-25);
    border-radius: 10px;
    bottom: 25px;
    box-shadow: var(--l-small);
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: center;
    left: 50%;
    padding: 4px;
    position: fixed;
    transform: translateX(-50%);
    z-index: 1;
    li {
        background: transparent;
        border-radius: 8px;
        color: var(--text);
        list-style: none;
        padding: 4px 12px;
        transition: var(--t-regular);
        text-align: center;
        text-decoration: none;
        width: 45px;
        &:hover {
            background: var(--foreground);
            a {color: var(--text);}
        }
        &:active {
            background: var(--text);
            box-shadow: inset 0px 0px 4px rgba(var(--text-rgb), .15);
            &:hover {a {color: var(--foreground);}}
            a {color: var(--foreground);}
        }
        &.selected {
            background: var(--text);
            box-shadow: inset 0px 0px 4px rgba(var(--text-rgb), .15);
            &:hover {a {color: var(--foreground);}}
            a {
                color: var(--foreground);
                font-family: "secondary", sans-serif;
            }
        }
        a {
            font-family: "primary", sans-serif;
            transition: var(--t-regular);
            &:after {display: none;}
        }
    }
}