.gradient {
    background-color: var(--primary);
    border-radius: 50%;
    filter: blur(50px);
    left: 0%;
    height: 500px;
    opacity: 1;
    position: absolute;
    top: 0%;
    width: 500px;
    z-index: -1;
}
  