.home {
    background-color: var(--foreground);
    background-image: linear-gradient(transparent, var(--primary));
    overflow-y: hidden !important;
    .grid_container {
        text-align: center;
    }
    .logo {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        width: 100%;
        h2 {
            font-size: 15vw;
            font-weight: 400;
            line-height: 80%;
            letter-spacing: -0.5vw;
            text-align: center;
            @media screen and (max-width: 768px) {font-size: 20vw;}
            span {
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                letter-spacing: inherit;
                text-align: inherit;
            }
        }
    }
}