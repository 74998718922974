.about {
    background-color: var(--foreground);
    .bcrvlh {
        filter: grayscale(1);
        opacity: .75;
        transition: var(--t-regular);
        &:hover {
            filter: grayscale(0);
        }
    }
}